h1 {
  color: #0F0A28;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
}

p {
  color: #575368;
  font-family: "Open Sans";
  font-size: 12px;
}

a {
  color: #1496BE;
  font-family: "Open Sans";
  font-size: 12px;
  text-decoration: none;
}

.frame {
  width:952px; 
  height:100%; 
  margin:0px auto;
}

.top {
  width:100%;
  height: auto;

  padding-bottom: 40px;
}

.top img {
  width: 400px;
  height: 74px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.top p {
  color: #575368;
  font-family: "Open Sans";
  font-size: 16px;
}

.middle {
  width: 920px;
  height: 620px;
}

.middle .left {
  width: 580px;
  height: 100%;
  float: left;
}

.middle .left img {
  width: 504px;
  height: 449px;
}

.middle .right {
  width: 340px;
  height: auto;
  float: right;
}

.middle .right .signup {
  width: 100%;
  height: auto;

  border-radius: 8px 8px 8px 8px;
  border-color: #B7B5BE;
  border-width: 1px;
  border-style: solid;
  text-align: center;
}

.middle .right .signup .title {
  margin-top: 32px;
  margin-bottom: 40px;
}

.middle .right .signup form {
  text-align: center;
}

.middle .right .signup form input {
  width: 272px;
  height: 40px;
  border-radius: 6px;
  background-color: #e7e6e9;
  border-width: 0px;
  margin-bottom: 8px;
  padding: 0 16px 0 16px;

  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
}

.middle .right .signup form input::placeholder {
  color: #868493;
}

.middle .right .signup form input:-ms-input-placeholder {
  color: #868493;
}

.middle .right .signup form input::-ms-input-placeholder {
  color: #868493;
}

.middle .right .signup form input:focus{
  outline: none;
}

.middle .right .signup form .submit{
  width: 304px;
  height: 48px;
  margin: auto;
  border-radius: 24px;
  background-color: #5ac3dc;
  margin-top: 8px;
  display: flex;
  cursor: pointer;
}

.middle .right .signup form .submit p {
  margin: auto;
  text-align: center;
  color: #ffffff;    
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
}

.middle .right .signup .errmsg {
  color: #ff0000;    
  font-family: Poppins;
  font-size: 15px;
  margin-top: 20px;
}

.middle .right .signup .policy {
  margin-top: 60px;
  margin-bottom: 32px;
}

.middle .right .signin {
  width: 100%;
  height: 70px;
  margin-top: 8px;

  border-radius: 8px 8px 8px 8px;
  border-color: #B7B5BE;
  border-width: 1px;
  border-style: solid;
  text-align: center;
}

.middle .right .signin p {
  font-size: 16px;
}

.middle .right .signin a {
  font-size: 16px;
  font-weight: 600;
}

.bottom {
  width: 100%;
  height: 22px;

  margin-top: 85px;
  margin-bottom: 18px;
}

.bottom .left {
  width: 200px;
  height: 100%;
  float: left;
}

.bottom .left a {
  font-size: 16px;
  font-weight: 600;
}

.bottom .right {
  width: auto;
  height: 100%;
  float: right;
}

.bottom .right p {
  margin-top: 0px;
  height: 100%;
}

.center {
  float: center;
  text-align: center;
}

.vertical-center {
	justify-content: center;
  flex-direction: column;
	display: flex;
}

.vertical-direction {
  flex-direction: column;
	display: flex;
}

.horizontal-center {
  justify-content: center;
  flex-direction: row;
	display: flex;
}

.horizontal-direction {
  flex-direction: row;
	display: flex;
}


.padding-t-8 {
  padding-top: 8px;
}

.padding-b-8 {
	padding-bottom: 8px;
}

.padding-t-16 {
	padding-top: 16px;
}

.padding-b-16 {
	padding-bottom: 16px;
}

.padding-l-8 {
	padding-left: 8px;
}

.padding-l-16 {
	padding-left: 16px;
}

.margin-t-8 {
	margin-top: 8px
}

.margin-t-16 {
	margin-top: 16px;
}

.margin-b-8 {
	margin-bottom: 8px;
}

.margin-b-16 {
	margin-bottom: 16px;
}
