body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  color: #0F0A28;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
}

p {
  color: #575368;
  font-family: "Open Sans";
  font-size: 12px;
}

a {
  color: #1496BE;
  font-family: "Open Sans";
  font-size: 12px;
  text-decoration: none;
}

.frame {
  width:952px; 
  height:100%; 
  margin:0px auto;
}

.top {
  width:100%;
  height: auto;

  padding-bottom: 40px;
}

.top img {
  width: 400px;
  height: 74px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.top p {
  color: #575368;
  font-family: "Open Sans";
  font-size: 16px;
}

.middle {
  width: 920px;
  height: 620px;
}

.middle .left {
  width: 580px;
  height: 100%;
  float: left;
}

.middle .left img {
  width: 504px;
  height: 449px;
}

.middle .right {
  width: 340px;
  height: auto;
  float: right;
}

.middle .right .signup {
  width: 100%;
  height: auto;

  border-radius: 8px 8px 8px 8px;
  border-color: #B7B5BE;
  border-width: 1px;
  border-style: solid;
  text-align: center;
}

.middle .right .signup .title {
  margin-top: 32px;
  margin-bottom: 40px;
}

.middle .right .signup form {
  text-align: center;
}

.middle .right .signup form input {
  width: 272px;
  height: 40px;
  border-radius: 6px;
  background-color: #e7e6e9;
  border-width: 0px;
  margin-bottom: 8px;
  padding: 0 16px 0 16px;

  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
}

.middle .right .signup form input::-webkit-input-placeholder {
  color: #868493;
}

.middle .right .signup form input::-ms-input-placeholder {
  color: #868493;
}

.middle .right .signup form input::placeholder {
  color: #868493;
}

.middle .right .signup form input:-ms-input-placeholder {
  color: #868493;
}

.middle .right .signup form input::-ms-input-placeholder {
  color: #868493;
}

.middle .right .signup form input:focus{
  outline: none;
}

.middle .right .signup form .submit{
  width: 304px;
  height: 48px;
  margin: auto;
  border-radius: 24px;
  background-color: #5ac3dc;
  margin-top: 8px;
  display: flex;
  cursor: pointer;
}

.middle .right .signup form .submit p {
  margin: auto;
  text-align: center;
  color: #ffffff;    
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
}

.middle .right .signup .errmsg {
  color: #ff0000;    
  font-family: Poppins;
  font-size: 15px;
  margin-top: 20px;
}

.middle .right .signup .policy {
  margin-top: 60px;
  margin-bottom: 32px;
}

.middle .right .signin {
  width: 100%;
  height: 70px;
  margin-top: 8px;

  border-radius: 8px 8px 8px 8px;
  border-color: #B7B5BE;
  border-width: 1px;
  border-style: solid;
  text-align: center;
}

.middle .right .signin p {
  font-size: 16px;
}

.middle .right .signin a {
  font-size: 16px;
  font-weight: 600;
}

.bottom {
  width: 100%;
  height: 22px;

  margin-top: 85px;
  margin-bottom: 18px;
}

.bottom .left {
  width: 200px;
  height: 100%;
  float: left;
}

.bottom .left a {
  font-size: 16px;
  font-weight: 600;
}

.bottom .right {
  width: auto;
  height: 100%;
  float: right;
}

.bottom .right p {
  margin-top: 0px;
  height: 100%;
}

.center {
  float: center;
  text-align: center;
}

.vertical-center {
	justify-content: center;
  flex-direction: column;
	display: flex;
}

.vertical-direction {
  flex-direction: column;
	display: flex;
}

.horizontal-center {
  justify-content: center;
  flex-direction: row;
	display: flex;
}

.horizontal-direction {
  flex-direction: row;
	display: flex;
}


.padding-t-8 {
  padding-top: 8px;
}

.padding-b-8 {
	padding-bottom: 8px;
}

.padding-t-16 {
	padding-top: 16px;
}

.padding-b-16 {
	padding-bottom: 16px;
}

.padding-l-8 {
	padding-left: 8px;
}

.padding-l-16 {
	padding-left: 16px;
}

.margin-t-8 {
	margin-top: 8px
}

.margin-t-16 {
	margin-top: 16px;
}

.margin-b-8 {
	margin-bottom: 8px;
}

.margin-b-16 {
	margin-bottom: 16px;
}

.sign_in {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    z-index: 999;
}

.sign_in p {
    color: #575368;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
}

.sign_in h1 {
    color: #0f0a28;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
}

.sign_in .bg {
    width: 100%;
    height: 100%;
}
  
.sign_in .container {
    width: 750px;
    height: 480px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 8px 8px 8px 8px;
    background-color: #ffffff;
    overflow: hidden;
    text-align: center;
    padding-top: 32px;

    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

    -webkit-animation: slide-in .4s .0s backwards;
    animation: slide-in .4s .0s backwards;
}

@-webkit-keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
}

.sign_in .logo {
    width: 338px;
    height: 61px;
    padding: 5px 0 39px 0;
    margin: auto;
}

.sign_in form {
    text-align: center;
}

.sign_in form input {
    width: 336px;
    height: 48px;
    border-radius: 28px;
    background-color: #e7e6e9;
    border-width: 0px;
    margin-bottom: 8px;
    padding: 0 24px 0 24px;

    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
}

.sign_in form input::-webkit-input-placeholder {
    color: #868493;
}

.sign_in form input::-ms-input-placeholder {
    color: #868493;
}

.sign_in form input::placeholder {
    color: #868493;
}

.sign_in form input:-ms-input-placeholder {
    color: #868493;
}

.sign_in form input::-ms-input-placeholder {
    color: #868493;
}

.sign_in form input:focus{
    outline: none;
}
 
.sign_in form .submit{
    width: 176px;
    height: 48px;
    margin: auto;
    border-radius: 24px;
    background-color: #5ac3dc;
    margin-top: 24px;
    display: flex;
    cursor: pointer;
}

.sign_in form .submit p {
    margin: auto;
    text-align: center;
	color: #ffffff;    
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
}

.sign_in .container .errmsg {
    color: #ff0000;    
    font-family: Poppins;
    font-size: 15px;
    margin-top: 30px;
}

.sign_in .container .close {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 16px;
    left: 715px;
    cursor: pointer;
}

.sign_in .container .sign_up {
    padding-top: 16px;
}

.sign_in .container .sign_up a {
    color: #5ac3dc;
    font-weight: 600;
    text-decoration: none;
}

.sign_in .container .tos {
    padding-top: 62px;
}

.sign_in .container .tos a {
    color: #0096ff;
    text-decoration: none;
}
